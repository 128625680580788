import React, {
  Dispatch,
  lazy,
  SetStateAction,
  Suspense,
  useCallback,
  useMemo,
  useState,
} from "react";
import {
  CollectionDataOrderBy,
  CollectionFilterByValue,
  LiteCollectionListDto,
  LiteDashboardCollectionDto,
} from "src/api/types";
import { Collection } from "./CollectionTable.types";
import useTopCollectionsData from "../../Dashboard/useTopCollectionsData";
import useGetCollectionsOutOfStockStatus from "../useGetCollectionsOutOfStockStatus";
import { useMultiStore } from "src/lite/helpers/useMultiStore";
import useInstalledMoreThanADay from "../../../../helpers/hooks/useInstalledMoreThanADay";
import { CollectionBadge } from "src/lite/components/CollectionBadges";
import { Period } from "../../Dashboard/util";
import { TableContent } from "./TableContent";

const ClickthroughRateModal = lazy(
  () => import("../../Dashboard/Modal/ClickthroughRateModal")
);

const LazySpline = lazy(() =>
  import("./LazySpline").then((module) => ({
    default: module.LazySpline,
  }))
);

const fakeDataWhileLoading: Collection[] = Array.from(
  { length: 12 },
  (_, index) => {
    const collection: Collection = {
      coverImages: undefined,
      productImages: undefined,
      title: "",
      numProducts: 0,
      hasDepictConfiguration: true,
      syncBackToShopify: true,
      id: index.toString(),
      badges: [],
      missingInSubStores: [],
      views: null,
      clicks: null,
      clickthrough_rate: null,
    };

    return collection;
  }
);

interface CollectionTableProps {
  collections: LiteDashboardCollectionDto[] | LiteCollectionListDto[];
  isLoading: boolean;
  noRowsOverlayComponent?: JSX.Element;
  onSearch: (search: string) => void;
  setOrderBy: React.Dispatch<React.SetStateAction<CollectionDataOrderBy>>;
  orderBy: CollectionDataOrderBy;
  loadMoreRef: (index: number) => (element: HTMLElement | null) => void;
  onUnPublishDepict: (collectionId: string) => void;
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
  setReverseSort: Dispatch<SetStateAction<boolean>>;
  filterBy: CollectionFilterByValue;
  setFilterBy: React.Dispatch<React.SetStateAction<CollectionFilterByValue>>;
}

export const CollectionTable = ({
  collections,
  isLoading,
  onSearch,
  setOrderBy,
  orderBy,
  loadMoreRef,
  noRowsOverlayComponent: noRowsFallback,
  onUnPublishDepict,
  period,
  setPeriod,
  setReverseSort,
  filterBy,
  setFilterBy,
}: CollectionTableProps) => {
  const { byShopId: multiStores } = useMultiStore();

  const topCollectionData = useTopCollectionsData({
    from_date: period.fromDate,
    to_date: period.toDate,
  });

  const [topPopularCollections, topConvertingCollections] = useMemo(() => {
    const topPopularCollections = new Map<string, CollectionBadge>();
    const topConvertingCollections = new Map<string, CollectionBadge>();

    topCollectionData.data?.top_clicked_collections.forEach(
      (collection, index) => {
        topPopularCollections.set(collection.collection_id, {
          type: "popular",
          clicks: collection.clicks,
          rank: index + 1,
        });
      }
    );

    topCollectionData.data?.top_ctr_collections.forEach((collection, index) => {
      topConvertingCollections.set(collection.collection_id, {
        type: "conversion_rate",
        conversionRate: collection.clickthrough_rate || 0,
        rank: index + 1,
      });
    });

    return [topPopularCollections, topConvertingCollections];
  }, [topCollectionData.data]);

  const { data: outOfStockStatus } = useGetCollectionsOutOfStockStatus();

  const data = useMemo(() => {
    if (isLoading) {
      return fakeDataWhileLoading;
    }

    return collections.map((collection) => {
      const syncBackToShopify = collection.sync_back_to_shopify;
      const hasDepictConfiguration = collection.has_depict_configuration;

      const badges = [
        topConvertingCollections?.get(collection.collection_id),
        topPopularCollections?.get(collection.collection_id),
      ].filter((badge): badge is CollectionBadge => !!badge);

      const productsOutOfStock = outOfStockStatus?.[collection.collection_id];

      if (productsOutOfStock && productsOutOfStock.length > 0) {
        badges.push({
          type: "out_of_stock",
          mainProductIds: productsOutOfStock,
        });
      }

      const missingShopIds = Object.keys(multiStores ?? {}).filter(
        (shopId) => !collection.connected_shop_ids?.includes(shopId)
      );

      if (syncBackToShopify) {
        badges.unshift({ type: "live" });
      } else if (hasDepictConfiguration) {
        badges.unshift({ type: "draft" });
      }

      const _collection: Collection = {
        id: collection.collection_id,
        coverImages: collection.image_urls,
        productImages: collection.product_images,
        title: collection.title,
        syncBackToShopify,
        hasDepictConfiguration,

        numProducts: collection.n_products,
        badges,
        missingInSubStores:
          missingShopIds && multiStores
            ? (missingShopIds
                .map((shopId) => multiStores[shopId]?.shopify_base_url)
                .filter((d) => d) as string[])
            : [],
        views:
          "selected_period_data" in collection
            ? collection.selected_period_data.views
            : null,
        clicks:
          "selected_period_data" in collection
            ? collection.selected_period_data.clicks
            : null,
        clickthrough_rate:
          "selected_period_data" in collection
            ? collection.selected_period_data.clickthrough_rate
            : null,
      };

      return _collection;
    });
  }, [
    collections,
    isLoading,
    multiStores,
    outOfStockStatus,
    topConvertingCollections,
    topPopularCollections,
  ]);

  const [selectedDashboardCollectionId, setSelectedDashboardCollectionId] =
    useState<string | undefined>(undefined);

  const onClose = useCallback(
    () => void setSelectedDashboardCollectionId(undefined),
    [setSelectedDashboardCollectionId]
  );

  const installedMoreThanADay = useInstalledMoreThanADay();

  return (
    <>
      <Suspense fallback={"Loading…"}>
        <LazySpline />
      </Suspense>
      <TableContent
        onSearch={onSearch}
        collections={data}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        isLoading={isLoading}
        loadMoreRef={loadMoreRef}
        setSelectedCollectionId={setSelectedDashboardCollectionId}
        onUnPublishDepict={onUnPublishDepict}
        noRowsFallback={noRowsFallback}
        collectingData={!installedMoreThanADay}
        period={period}
        setPeriod={setPeriod}
        setReverseSort={setReverseSort}
      />
      <Suspense>
        {selectedDashboardCollectionId && (
          <ClickthroughRateModal
            onSetSelectedCollectionId={setSelectedDashboardCollectionId}
            selectedCollectionId={selectedDashboardCollectionId}
            open={selectedDashboardCollectionId !== undefined}
            onClose={onClose}
            defaultPeriod={period}
          />
        )}
      </Suspense>
    </>
  );
};
