import { Collection } from "./CollectionTable.types";
import {
  CollectionDataOrderBy,
  CollectionFilterByValue,
} from "../../../../api/types";
import { Period } from "../../Dashboard/util";
import React, { Dispatch, SetStateAction } from "react";
import { css, useTheme } from "@emotion/react";
import { useLocalStorage } from "usehooks-ts";
import { Search } from "../../../../alignUI/Search/Search";
import { DatePicker } from "./DatePicker";
import {
  SwitchToggleItem,
  SwitchToggleRoot,
} from "../../../../alignUI/SwitchToggle/SwitchToggle";
import { List, SquaresFour } from "@phosphor-icons/react";
import { CollectionGrid } from "./CollectionGrid";
import { CollectionList } from "./CollectionList";
import {
  TabMenuHorizontal,
  TabMenuHorizontalItem,
} from "../../../../alignUI/TabMenuHorizontal/TabMenuHorizontal";

const collectionViewTabs: {
  name: string;
  key: CollectionFilterByValue | "all";
}[] = [
  { name: "Live", key: "live" },
  { name: "Draft", key: "draft" },
  { name: "All", key: "all" },
];

export function TableContent({
  collections,
  onSearch,
  setOrderBy,
  orderBy,
  isLoading,
  loadMoreRef,
  onUnPublishDepict,
  noRowsFallback: noRowsFallback,
  setSelectedCollectionId,
  collectingData,
  period,
  setPeriod,
  setReverseSort,
  filterBy,
  setFilterBy,
}: {
  collections: Collection[];
  onSearch: (search: string) => void;
  orderBy: CollectionDataOrderBy;
  isLoading: boolean;
  loadMoreRef: (index: number) => (element: HTMLElement | null) => void;
  setSelectedCollectionId: (collectionId: string) => void;
  onUnPublishDepict: (collectionId: string) => void;
  noRowsFallback?: JSX.Element;
  collectingData: boolean;
  period: Period;
  setPeriod: React.Dispatch<React.SetStateAction<Period>>;
  setReverseSort: Dispatch<SetStateAction<boolean>>;
  setOrderBy: Dispatch<SetStateAction<CollectionDataOrderBy>>;
  filterBy: CollectionFilterByValue;
  setFilterBy: React.Dispatch<React.SetStateAction<CollectionFilterByValue>>;
}) {
  const theme = useTheme();

  const [view, setView] = useLocalStorage<"grid" | "list">(
    "depict_showCollectionsGridList_v1",
    "grid"
  );

  return (
    <div
      css={css`
        display: flex;
        padding: 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        flex: 1;

        border-radius: 16px;
        border: 1px solid ${theme.colors.stroke["soft-200"]};
        background: ${theme.colors.bg["white-0"]};
      `}
    >
      <div
        css={[
          (theme) => css`
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 12px;
            width: 100%;
            border-bottom: 1px solid ${theme.colors.stroke["soft-200"]};

            @media (max-width: ${theme.breakpoints.md}px) {
              flex-direction: column-reverse;
              gap: 4px;
              align-items: start;
            }
          `,
        ]}
      >
        <OrderByTabs filterBy={filterBy} setFilterBy={setFilterBy} />
        <div
          css={css`
            display: flex;
            gap: 8px;
            @media (max-width: ${theme.breakpoints.md}px) {
              justify-content: space-between;
              width: 100%;
            }
          `}
        >
          <Search
            openWidth={120}
            onSearch={onSearch}
            extraCss={css`
              min-width: unset;
            `}
          />
          <div
            css={css`
              display: flex;
              gap: 8px;
            `}
          >
            <DatePicker period={period} setPeriod={setPeriod} />
            <div
              css={css`
                flex: 0;
              `}
            >
              <SwitchToggleRoot
                value={view}
                onValueChange={(value) => {
                  setView(value as "grid" | "list");
                }}
              >
                <SwitchToggleItem LeftIcon={SquaresFour} value={"grid"} />
                <SwitchToggleItem LeftIcon={List} value={"list"} />
              </SwitchToggleRoot>
            </div>
          </div>
        </div>
      </div>

      <>
        {collections.length === 0 ? (
          <div
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              width: 100%;
              padding: 20px;
            `}
          >
            {noRowsFallback}
          </div>
        ) : (
          <>
            {view === "grid" ? (
              <CollectionGrid
                collections={collections}
                isLoading={isLoading}
                loadMoreRef={loadMoreRef}
                onUnPublishDepict={onUnPublishDepict}
                setSelectedCollectionId={setSelectedCollectionId}
              />
            ) : (
              <CollectionList
                collections={collections}
                orderBy={orderBy}
                isLoading={isLoading}
                loadMoreRef={loadMoreRef}
                onUnPublishDepict={onUnPublishDepict}
                setSelectedCollectionId={setSelectedCollectionId}
                collectingData={collectingData}
                setReverseSort={setReverseSort}
                setOrderBy={setOrderBy}
              />
            )}
          </>
        )}
      </>
    </div>
  );
}

const OrderByTabs = ({
  filterBy,
  setFilterBy,
}: {
  filterBy: CollectionFilterByValue;
  setFilterBy: (orderBy: CollectionFilterByValue) => void;
}) => {
  return (
    <TabMenuHorizontal
      value={filterBy || "all"}
      onValueChange={(value: string | undefined) => {
        if (value === "all") {
          value = undefined;
        }
        setFilterBy(value as CollectionFilterByValue);
      }}
    >
      {collectionViewTabs.map((collectionOrderByValue) => (
        <TabMenuHorizontalItem
          key={collectionOrderByValue.name}
          value={collectionOrderByValue.key || "all"}
        >
          {collectionOrderByValue.name}
        </TabMenuHorizontalItem>
      ))}
    </TabMenuHorizontal>
  );
};
