import { useInfiniteQuery } from "@tanstack/react-query";
import useAuthorizedApi from "src/helpers/hooks/app/useAuthorizedApi";
import { getLiteQueryKey, QueryId } from "src/lite/queries";
import useMerchantId from "../../../helpers/hooks/app/useMerchantId";
import { getData } from "src/api/authorizedApi";
import { components } from "../../../api/generated/openapi";

export function useSortedCollectionsWithData(
  incomingParams: components["schemas"]["GetDataSortedPaginatedCollectionsRequest"]
) {
  const { api } = useAuthorizedApi();
  const { merchantId } = useMerchantId();

  const queryKey = getLiteQueryKey(QueryId.GetSortedCollectionData, {
    merchantId,
    ...incomingParams,
  });

  return useInfiniteQuery({
    queryKey,
    queryFn: async (context: { pageParam: undefined | number }) => {
      const response = await api.POST(`/collections/data-sorted-by`, {
        params: {
          query: {
            merchant_id: merchantId,
          },
        },
        body: {
          ...incomingParams,
          offset: context.pageParam,
        } satisfies components["schemas"]["GetDataSortedPaginatedCollectionsRequest"],
      });
      return getData(response);
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage) => {
      if (lastPage.has_next) {
        return lastPage.offset;
      }
    },
    staleTime: 1000 * 60,
  });
}
