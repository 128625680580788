import { Collection } from "./CollectionTable.types";
import { CollectionDataOrderBy } from "../../../../api/types";
import useIsMobile from "../../../../helpers/hooks/useIsMobile";
import { CollectionImage } from "../CollectionImage";
import {
  BadgeGroup,
  CollectionStateBadge,
} from "../../../components/CollectionBadges";
import { css, Interpolation, Theme, useTheme } from "@emotion/react";
import React, { Dispatch, SetStateAction } from "react";
import { Link } from "react-router";
import { ActionButtons } from "./ActionButtons";
import { Timer } from "@phosphor-icons/react";
import useMerchantId from "src/helpers/hooks/app/useMerchantId";

export function CollectionList({
  collections,
  orderBy,
  isLoading,
  loadMoreRef,
  onUnPublishDepict,
  setSelectedCollectionId,
  collectingData,
  setReverseSort,
  setOrderBy,
}: {
  collections: Collection[];
  orderBy: CollectionDataOrderBy;
  isLoading: boolean;
  loadMoreRef: (index: number) => (element: HTMLElement | null) => void;
  setSelectedCollectionId: (collectionId: string) => void;
  onUnPublishDepict: (collectionId: string) => void;
  collectingData: boolean;
  setReverseSort: Dispatch<SetStateAction<boolean>>;
  setOrderBy: Dispatch<SetStateAction<CollectionDataOrderBy>>;
}) {
  const isMobile = useIsMobile();
  return (
    <div
      css={(theme) => css`
        width: 100%;

        margin: 0 0 8px 0;
        padding: 8px;

        display: grid;
        grid-template-columns:
          120px minmax(20px, 1fr) repeat(5, minmax(min-content, 0.2fr))
          auto;
        @media (max-width: ${theme.breakpoints.sm}px) {
          grid-template-columns: 86px minmax(0, 1fr) 80px auto;
        }
        align-items: center;
      `}
    >
      {!isMobile && (
        <div
          css={(theme) => css`
            display: grid;
            grid-template-columns: subgrid;
            border-radius: 8px;
            grid-column: span 8;
            background: ${theme.colors.bg["weak-50"]};
            margin-bottom: 8px;

            // Sticky header
            @media (min-width: ${theme.breakpoints.sm + 1}px) {
              position: sticky;
              top: 0;
              z-index: 10;
            }
          `}
        >
          <HeaderElement
            title={"A-Z"}
            setReverseSort={setReverseSort}
            customCss={css`
              grid-column: 1/3;
            `}
          />
          <HeaderElement
            setReverseSort={setReverseSort}
            customCss={css`
              grid-column: 3/4;
            `}
            title={"Status"}
          />
          <HeaderElement setReverseSort={setReverseSort} title={"Products"} />
          <HeaderElement setReverseSort={setReverseSort} title={"Clicks"} />
          <HeaderElement setReverseSort={setReverseSort} title={"CTR"} />
          <HeaderElement setReverseSort={setReverseSort} title={"Views"} />
        </div>
      )}

      {collections.map((collection, index) => (
        <Row
          key={collection.id}
          loadMoreRef={loadMoreRef(index)}
          collection={collection}
          isLoading={isLoading}
          setSelectedCollectionId={setSelectedCollectionId}
          onUnPublishDepict={onUnPublishDepict}
          collectingData={collectingData}
        />
      ))}
    </div>
  );
}

function Row({
  loadMoreRef,
  collection,
  isLoading,
  onUnPublishDepict,
  setSelectedCollectionId,
  collectingData,
}: {
  loadMoreRef: (element: HTMLElement | null) => void;
  collection: Collection;
  isLoading: boolean;
  onUnPublishDepict: (collectionId: string) => void;
  setSelectedCollectionId: (collectionId: string) => void;
  collectingData: boolean;
}) {
  const theme = useTheme();
  const isMobile = useIsMobile();
  const { merchantId } = useMerchantId();

  return (
    <>
      <div
        ref={loadMoreRef}
        css={css`
          display: grid;
          align-items: center;
          grid-column: span 8;
          grid-template-columns: subgrid;
          padding: 4px;
          border-radius: 8px;

          &:hover,
          &:has([aria-expanded="true"]) {
            .list-action-buttons {
              visibility: visible;
            }
          }

          .collection-link {
            color: ${theme.colors.text["strong-950"]};
            text-decoration: none;
            &:hover {
              text-decoration: underline;
            }
          }
          &:has(.collection-link:hover) {
            background: ${theme.colors.bg["weak-50"]};
          }

          /* For link overlay */
          position: relative;
          button,
          .lift {
            z-index: 1;
            position: relative;
          }

          :not(:last-of-type):after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: calc(100% - 98px);
            border-bottom: 1px solid ${theme.colors.stroke["soft-200"]};
          }
        `}
      >
        <CollectionImage
          showPlaceholder={isLoading}
          coverImages={collection.coverImages}
          productImages={collection.productImages}
          size="small"
        />
        <div
          css={css`
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          `}
        >
          <Link
            to={`/${merchantId}/collections/${collection.id}`}
            className="collection-link"
            css={[
              css`
                &:before {
                  position: absolute;
                  display: block;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  content: "";
                }
              `,
              (theme) => theme.typography["label-m"],
            ]}
            title={collection.title}
          >
            {collection.title}
          </Link>
        </div>
        {isMobile && (
          <div>
            <CollectionStateBadge
              live={collection.syncBackToShopify}
              draft={collection.hasDepictConfiguration}
            />
          </div>
        )}

        {!isMobile && (
          <>
            {/* Status */}
            <div
              css={[
                (theme) => theme.typography["label-s"],
                css`
                  padding: 12px 8px;
                  display: flex;
                  gap: 4px;
                `,
              ]}
            >
              {!isLoading && <BadgeGroup badges={collection.badges} />}
            </div>
            {/* Products */}
            <div
              css={[
                (theme) => theme.typography["label-s"],
                css`
                  padding: 12px 8px;
                `,
              ]}
            >
              {!isLoading ? collection.numProducts : null}
            </div>
            <DataColumn
              data={collection.clicks?.toString()}
              collectingData={collectingData}
            />
            <DataColumn
              data={
                collection.clickthrough_rate
                  ? collection.clickthrough_rate?.toFixed(2) + "%"
                  : undefined
              }
              collectingData={collectingData}
            />
            <DataColumn
              data={collection.views?.toString()}
              collectingData={collectingData}
            />
          </>
        )}
        {/* Actions */}
        {!isLoading && (
          <ActionButtons
            collection={collection}
            onUnPublishDepict={onUnPublishDepict}
            setSelectedCollectionId={setSelectedCollectionId}
            className="list-action-buttons"
          />
        )}
      </div>
    </>
  );
}

const HeaderElement = ({
  title,
  customCss,
  setReverseSort,
}: {
  title: string;
  customCss?: Interpolation<Theme>;
  setReverseSort: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <div
        css={(theme) => [
          theme.typography["paragraph-s"],
          css`
            padding: 10px 8px;
            color: ${theme.colors.text["sub-600"]};
          `,
          customCss,
        ]}
      >
        {title}
      </div>
    </>
  );
};

const DataColumn = ({
  data,
  collectingData,
}: {
  data?: string;
  collectingData: boolean;
}) => {
  const theme = useTheme();
  return (
    data && (
      <>
        {collectingData ? (
          <div
            css={[
              (theme) => theme.typography["paragraph-xs"],
              css`
                padding: 12px 8px;
                color: ${theme.colors.text["sub-600"]};
                align-items: center;
                display: flex;
                gap: 4px;
              `,
            ]}
          >
            <Timer size={12} /> Collecting data
          </div>
        ) : (
          <div
            css={[
              (theme) => theme.typography["label-s"],
              css`
                padding: 12px 8px;
              `,
            ]}
          >
            {data}
          </div>
        )}
      </>
    )
  );
};
